import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { GedService } from "../utils/api/GedService";
import { ContractUserStore, ContractUser } from "../utils/types/ContractUserTypes";

export const StoreContractUser = create(
  persist<ContractUserStore>(
    (set) => ({
      isLoading: false,
      isFinished: false,
      searchUser: async (userName: string) => {
        try {
          set({ isLoading: true, isFinished: false });
          const data: Array<ContractUser> = await GedService.getUser(userName);
          set(() => ({ isLoading: false, responseUser: data, isFinished: true }));
        } catch (err: any) {
          const error =
            err?.message || err?.data?.message || "Unexpected network error.";
          set(() => ({
            isLoading: false,
            responseUser: [],
            error,
            isFinished: false,
          }));
        }
      },
    }),
    {
      name: "ref-store",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
