import { Contract } from "../../../utils/types/ContractTypes";
import { Alert, Box, Button, Card, CardActions, CardContent, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import ApartmentIcon from '@mui/icons-material/Apartment';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import * as moment from 'moment'
import 'moment/locale/fr';
//import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import * as React from "react";
//import { StoreSharePointSite, StoreSharePointLists, StoreSharePointAddItem } from "../../../hooks/SharePointStore";
import { GedSharepointListItem } from "../../../utils/types/SharePointTypes";
import { StoreAddContractStatus, StoreContractStatus, StoreRequestProjectWorkspace, StoreGetCodeSp } from "../../../hooks/ContractStore";
import { useEffect } from "react";
import ContractUserDialog from "./ContractUserDialog";
export function ContractDetails({ choosenContract, currentUser }: any) {


  const { searchStatus, responseStatus } = StoreContractStatus();
  const { addStatus, responseAddedItem } = StoreAddContractStatus();
  const [isClicked, setIsClicked] = React.useState(false);
  const { getCodeSp, responseCodeSp } = StoreGetCodeSp();

  const { requestProjectWorkspace, responseRequestProjectWorkspace, errorRequestProjectWorkspace } = StoreRequestProjectWorkspace();

  let choosenContractOne: Contract = choosenContract[0];

  useEffect(() => {
    searchStatus(choosenContractOne.projectId)
  }, [choosenContractOne, responseAddedItem]);

  useEffect(() => {
    getCodeSp(choosenContractOne.serviceId)
  }, [choosenContractOne]);

  useEffect(() => {
    requestWorkspace();
  }, [isClicked]);



  function requestWorkspace() {

    var itemToAdd: GedSharepointListItem = {
      title: choosenContractOne.projectName,
      idIovision: choosenContractOne.projectId,
      typeProject: '',
      contactChefProjet: choosenContractOne.projectSupervisorEmail,
      contactCommercial: choosenContractOne.projectSalesRepresentativeEmail,
      client: choosenContractOne.customerName,
      serviceId: choosenContractOne.serviceId,
      dateProjet: choosenContractOne.startDate.toString().split('T')[0]
    };
    if (isClicked == true && responseCodeSp?.code_sp != null) {
      requestProjectWorkspace(choosenContractOne.projectId, itemToAdd);
      addStatus(choosenContractOne.projectId);
    }
  }


  const card = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5" component={'span'}>
          Identifiant projet:  {choosenContractOne.projectId}
        </Typography>
        <br />
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {choosenContractOne.projectKey}: {choosenContractOne.projectName}
        </Typography>
        <br />
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ApartmentIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary={choosenContractOne.serviceName} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <CoPresentIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary={choosenContractOne.customerName} />
            </ListItemButton>
          </ListItem>
        </List>
      </CardContent>
      <CardActions>
        {(responseStatus?.length == 0 && responseCodeSp?.code_sp != null) && (<Button
          variant="contained"
          size="small"
          type="submit"
          disabled={responseStatus?.length != 0}
          onClick={() => setIsClicked(true)}
          sx={{
            color: "background.paper",
            borderColor: "background.paper",
            marginRight: 5,
          }}
        >
          création de l'espace documentaire de l'étude
        </Button>
        )}
        {(responseCodeSp?.code_sp == null) && (<Alert severity="error">Le code sharepoint du service n'existe pas</Alert>)}
        {(responseStatus?.length != 0) && (
          <>
            <Alert severity="info">La demande de création a été effectuée par {responseStatus?.[0]?.created_by} le {moment.locale("fr") && moment(responseStatus?.[0]?.created).format('LLL')}</Alert>
            <ContractUserDialog currentProjectId={choosenContractOne.projectId} currentAgencyName={choosenContractOne.serviceName} currentUserName={currentUser.displayName} />
          </>
        )}
      </CardActions>
    </React.Fragment>);

  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">{card}
      </Card>
      {(false && !errorRequestProjectWorkspace && responseRequestProjectWorkspace?.id != undefined) && (<Alert severity="success">L'espace est crée avec succès.</Alert>)}
      {(false && errorRequestProjectWorkspace != undefined && responseAddedItem != undefined) && (<Alert severity="error">Une erreur est survenue.</Alert>)}
    </Box>

  );
}