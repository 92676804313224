import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function HelpDialog({HelpMessageComponent,colorTheme}:any) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Aide
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="customized-dialog-title">
          Notice d'utilisation
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={() => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: {colorTheme},
          })}
        >
          <CloseIcon />
        </IconButton>
        <HelpMessageComponent/>
      </Dialog>
    </React.Fragment>
  );
}