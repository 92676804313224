
import { persist, createJSONStorage } from "zustand/middleware";
import { GedService } from "../utils/api/GedService";
import { AddUserMailStore, MailContent } from "../utils/types/MailNotifTypes";
import { create } from "zustand";

export const StoreUserMail = create(
  persist<AddUserMailStore>(
    (set) => ({
      isMailLoading: false,
      isMailFinished: false,
      sendRequest: async (mailContent: MailContent) => {
        try {
          set({ isMailLoading: true, isMailFinished: false });
          const data = await GedService.requestProjectUser(mailContent);
          set(() => ({ isMailLoading: false, responseMail: data, isMailFinished: true }));
        } catch (err: any) {
          const errorMail =
            err?.message || err?.data?.message || "Unexpected network error.";
          set(() => ({
            isMailLoading: false,
            errorMail,
            isMailFinished: false,
          }));
        }
      },
    }),
    {
      name: "ref-store",
      storage: createJSONStorage(() => localStorage),
    }
  )
);