import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Button, Dialog, DialogTitle, DialogContent } from "@mui/material";
import * as React from "react";
import ContractUserSearch from './ContractUserSearch';

export default function ContractUserDialog({ currentProjectId, currentUserName, currentAgencyName }: any) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button variant="contained"
                size="small" onClick={handleClickOpen}
                sx={{
                    color: "background.paper",
                    borderColor: "background.paper",
                    marginRight: 5,
                }}>
                Ajouter des collaborateurs
            </Button>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="customized-dialog-title">
                    Rechercher et ajouter des collaborateurs
                </DialogTitle>
                <DialogContent>
                    <ContractUserSearch  currentProjectId={currentProjectId} currentUserName={currentUserName} currentAgencyName={currentAgencyName}/>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}