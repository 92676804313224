import { Contract } from "../utils/types/ContractTypes";
import * as React from "react";
import { ContractDetails } from "../components/app/contracts/ContractDetails";
import ContractSearch from "../components/app/contracts/ContractSearch";;
import HelpDialog from "@front-ends/common/src/components/app/HelpDialog";
import "../styles/style.css";
import { HelpMessageTemplate } from "../components/ui/HelpMessage";


export type GraphData = {
    displayName: string,
    jobTitle: string,
    mail: string,
    businessPhones: string[],
    officeLocation: string
};
export default function Main({ graphData }: any) {
    //console.log(graphData);
    const [results, setResults] = React.useState<Contract[]>([]);
    const [contractDetails, setContractDetails] = React.useState(false);
    return (
        <div>
            <div className='row'>
                < ContractSearch results={results} setResults={setResults} setContractDetails={setContractDetails} /*userService = {graphData.department}*/ />
                < HelpDialog HelpMessageComponent={HelpMessageTemplate} colorTheme='#4ac7bf' />
            </div>
            <div>
            </div>
            <br />
            {contractDetails && results.length != 0 && (
                <ContractDetails choosenContract={results} currentUser = {graphData} />
            )}
        </div>
    );

}