type Props = {
    children?: React.ReactNode;
    NavBar?: any;
};

export const PageLayout: React.FC<Props> = ({children, NavBar}) => {
    return (
        <>
            <NavBar />
            <br />
            <br />
            <br />
            <br />
            {children}
        </>
    );
};