import { Alert, Box, Button } from "@mui/material";
import { Action, MailContent } from '../../../utils/types/MailNotifTypes';
import send_mail from "../../../assets/images/send_mail.png"
import { StoreUserMail } from "../../../hooks/MailStore";
import { useEffect, useState } from "react";
import * as React from "react";



export default function SendEmail({ users, currentProjectId, currentUserName, currentAgencyName }: any) {

    const { isMailLoading,isMailFinished,sendRequest } = StoreUserMail();
    const [isMailClicked, setIsMailClicked] = useState(false);

    useEffect(() => {
        if (isMailClicked && users.length != 0) {
            sendEmail();
        }
    }, [isMailClicked]);

    function sendEmail () {

        var mailToSend: MailContent = { actionName: Action.addUser, currentUserName: currentUserName, projectId: Number(currentProjectId), agencyName: currentAgencyName, userList: users };
        console.log("sending email");
        sendRequest(mailToSend);
    }

    return (
        <Box>
            {(isMailClicked && !isMailLoading && isMailFinished) && (
                <React.Fragment><Alert severity="success">La demande d'ajout a bien été envoyée.</Alert></React.Fragment>
            )}
            {(isMailClicked && !isMailLoading && !isMailFinished) && (
                <React.Fragment><Alert severity="error">Une erreur est survenue. Contactez un administrateur</Alert></React.Fragment>
            )}
            <Button
                variant="contained"
                size="small"
                disabled={users?.length != 0}
                onClick={() => setIsMailClicked(true)}
            >
                <img src={send_mail} alt="send_mail" width="20" height="20"/>
            </Button>
        </Box>
    );
}