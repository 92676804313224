//import { GedApi } from "./configs/Api";
import {GedApiBack} from "./configs/Api";
import { GedSharepointListItem, WorkspaceResponse } from "../../utils/types/SharePointTypes";
import { MailContent } from "../types/MailNotifTypes";

export const GedService = {

  getContract: async function (projectId:number/*,userService:string*/) {
    const response = await GedApiBack.request({
        url: `/project-iovision?q=${projectId}&page=10`,
        //url: `/project-iovision?q=${value}&service=${userService}&page=10`,
        method: "GET",
      })
    return response.data
  },

  getContractStatus: async function (value:number) {
    const response = await GedApiBack.request({
        url: `/project-status?q=${value}`,
        method: "GET",
      })
    return response.data
  },

  addContractStatus: async function (projectId:number) {
    const response = await GedApiBack.request({
        url: `/project-status/${projectId}`,
        method: "POST",
      })
    return response.data
  },

  requestProjectWorkspace: async function (projectId:number, data:GedSharepointListItem):Promise<WorkspaceResponse> {

    const response = await GedApiBack.request({
        url: `/project-iovision/${projectId}/workspace`,
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        data: data,
        body: 'raw'
       
      })
    return response.data
  },

  getCodeSp: async function (serviceId:number) {
    const response = await GedApiBack.request({
      url: `/service-lookup/${serviceId}`,
      method: "GET",
    })
  return response.data
  },

  getUser: async function (name:string) {
    const response = await GedApiBack.request({
        url: `/user/search?name=${name}`,
        method: "GET",
      })
    return response.data
  },

  requestProjectUser: async function (mailcontent:MailContent){
    const response = await GedApiBack.request({
        url: `/mail/users`,
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        data: mailcontent
       
      })
    return response.data
  },

}
