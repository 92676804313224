export interface MailContent {
  currentUserName: string;
  actionName: Action;
  projectId: number;
  agencyName: string;
  userList: string[];
}

export enum Action {
  archiving = "ARCHIVING",
  restoration = "RESTORATION",
  addUser = "ADD_USER",
}

export interface AddUserMailStore {
  errorMail?: string;
  isMailLoading?: boolean;
  isMailFinished?: boolean;
  responseMail?: string;
  sendRequest: (mailContent: MailContent) => Promise<void>;
}
