import { Autocomplete, TextField } from "@mui/material";
import * as React from 'react';


export default function AutocompleteSearch({optionsLabel,optionsName, keyLabel, searchSt, responseSt, addResultFunction }: any) {

  const [results, setResult] = React.useState([]);
  const [searchInput, setSearchInput] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const loading = open && searchInput.length > 2 && results != ([]);

  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {
      if (active) {
        setResult([]);
      }
    })();
    return () => {
      active = false;
    };
  }, [loading]);

  function searchItems(_event: React.SyntheticEvent, value: string) {
    setSearchInput(value)
    if (value !== '' && value.length > 2) {
      searchSt(value).then(() => {
        if (responseSt) {
          setResult(responseSt);
        }
      });
    }
  }

  return (
    <Autocomplete
      multiple
      id="autocomplete-search"
      options={responseSt ? responseSt : []}
      getOptionLabel={(option: any) => option[optionsLabel]}
      isOptionEqualToValue={(option, name) => option[optionsLabel] === name[optionsLabel]}
      onInputChange={searchItems}
      onChange={(_event, value) => {
        addResultFunction(value);
      }}
      loading={loading}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option[keyLabel]}>
            {option[optionsName]+ " - " +option[optionsLabel]}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label=""
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />)
}