

import { StoreContractUser } from '../../../hooks/ContractUserStore'

import AutocompleteSearch from '@front-ends/common/src/components/app/AutocompleteSearch';
import SendEmail from '../mail/SendMail';
import { ContractUser } from '../../../utils/types/ContractUserTypes';
import { Box, DialogActions, Typography } from '@mui/material';


export default function ContractUserSearch({ currentProjectId, currentUserName, currentAgencyName }: any) {

    const { searchUser, responseUser } = StoreContractUser();
    var projectUsers: string[] = []

    function addUsers(v: ContractUser[]) {

        v.forEach(u => {
            const choosenUser: ContractUser = u;
            projectUsers.push(choosenUser?.mail);
        })

    }


    return (
        <Box>
            <Typography gutterBottom>
            <AutocompleteSearch optionsLabel={"mail"} optionsName={"displayName"} keyLabel={"id"} searchSt={searchUser} responseSt={responseUser} addResultFunction={addUsers} />
            </Typography>
            <DialogActions>
            <SendEmail users={projectUsers} currentProjectId={currentProjectId} currentUserName={currentUserName} currentAgencyName={currentAgencyName} />
            </DialogActions>
        </Box>

    );
}